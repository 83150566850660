import PropTypes from 'prop-types';
import * as React from 'react';

import { Error } from './error';

function Input({
  isFullWidth,
  label,
  name,
  required = true,
  type = 'text',
  register,
  errors,
  labelHidden,
  bgTransparent,
}) {
  const MIN_LENGTH = type === 'tel' ? 8 : 2;
  return (
    <div className={isFullWidth ? 'sm:col-span-2' : ''}>
      <label htmlFor={name}>
        <span
          className={`${
            labelHidden ? 'sr-only' : 'block'
          } text-sm font-medium leading-5 text-gray-700`}
        >
          {label}
          {required && ' *'}
        </span>
        <div className="relative mt-1">
          <input
            id={name}
            name={name}
            type={type}
            required={required}
            aria-invalid={!!errors[name]}
            placeholder={label}
            ref={register({
              required: required && (
                <Error message={`${label} is a required field`} />
              ),
              minLength: {
                value: MIN_LENGTH,
                message: (
                  <Error
                    message={`${label} must be at least ${MIN_LENGTH} characters`}
                  />
                ),
              },
            })}
            className={`
            ${bgTransparent ? 'border border-gray-300' : 'border-none'}
            block w-full px-4 py-3 placeholder-black transition duration-150 ease-in-out bg-white rounded-none form-input`}
          />
        </div>
      </label>
      {errors[name]?.message}
    </div>
  );
}

Input.propTypes = {
  errors: PropTypes.object,
  isFullWidth: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  labelHidden: PropTypes.bool,
  bgTransparent: PropTypes.bool,
};

export { Input };
