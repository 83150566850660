module.exports = {
  title: 'R&M Aviation',
  description: '',
  author: '',
  phone: '+61 2 6582 0251',
  fax: '+61 2 6582 4630',
  email: 'admin@rmaviation.com.au',
  address: {
    line1: 'PO Box 9446',
    line2: 'Port Macquarie NSW 2444',
  },
  officeHours: 'Monday to Friday 9am–5pm',
  relationshipManager: {
    phone: '+61 412 064 790',
    email: 'kaylah@rmaviation.com.au',
  },
  siteUrl: 'https://www.rmaircraft.com.au',

  siteNavigation: [
    {
      label: 'Company Profile',
      slug: '/company-profile/',
    },
    {
      label: 'What We Do',
      slug: '/what-we-do/',
    },
    {
      label: 'Aircraft',
      slug: '/aircraft/',
    },
    {
      label: 'Our Team',
      slug: '/our-team/',
    },
    {
      label: 'Contact Us',
      slug: '/contact-us/',
    },
  ],
};
