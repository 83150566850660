import * as React from 'react';
import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';

interface Props {
  children: React.ReactNode;
  cta?: {
    slug: string;
    label: string;
  };
  heading?: string;
  logos?: {
    abbr: string;
    name: string;
    image: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    url: string;
  }[];
  image?: FluidObject;
  onGrey?: boolean;
  subheading?: string;
}

function HeadingWithCopy({
  children,
  cta,
  heading,
  image,
  logos,
  onGrey,
  subheading,
}: Props) {
  return (
    <article
      className={`
      ${onGrey ? 'bg-grey' : 'bg-white'}
      relative w-full px-4 pt-12 pb-16 mx-auto lg:pt-28 lg:pb-48 max-w-screen-2xl sm:px-6 lg:px-8`}
    >
      <svg
        className={`
          ${onGrey ? 'text-grey' : 'text-white'}
          absolute inset-x-0 top-0 transform -translate-y-full block w-full h-14 md:h-28`}
        fill="currentColor"
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="0,1 1,0 1,1" />
      </svg>
      <div className="grid justify-around gap-8 lg:grid-cols-2">
        <div className="max-w-prose">
          {heading && (
            <h2
              dangerouslySetInnerHTML={{ __html: heading }}
              className="heading-2 lg:text-center"
            />
          )}
          {subheading && (
            <p className="mt-4 text-lg font-medium lg:text-center text-teal">
              {subheading}
            </p>
          )}
          {image && (
            <div className="relative h-0 mt-8 aspect-w-16 aspect-h-9">
              <div className="absolute inset-0 flex">
                <GatsbyImage fluid={image} className="flex-1" />
              </div>
            </div>
          )}
          {logos && (
            <ul className="flex flex-wrap items-center justify-center -mx-4 -mt-4">
              {logos.map(({ abbr, name, image, url }) => (
                <li key={url} className="w-1/3 p-4">
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block"
                  >
                    {abbr ? (
                      <abbr title={name} className="sr-only">
                        {abbr}
                      </abbr>
                    ) : (
                      <span className="sr-only">{name}</span>
                    )}
                    <div className="relative h-0 aspect-w-4 aspect-h-3">
                      <div className="absolute inset-0 flex">
                        <GatsbyImage
                          fluid={image.childImageSharp.fluid}
                          imgStyle={{
                            objectFit: 'contain',
                          }}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div>
          <div className="prose">{children}</div>
          {cta && (
            <Link to={cta.slug} className="mt-8 button">
              {cta.label}
            </Link>
          )}
        </div>
      </div>
    </article>
  );
}

export { HeadingWithCopy };
