import React, { useState } from 'react';
import { Link } from 'gatsby';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { HiMenu, HiX } from 'react-icons/hi';

import config from '../utils/config';
import { socialLinks } from '../utils';
import { Logo } from './vectors';
import { useSiteContext } from '../hooks';

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { isHeaderActive } = useSiteContext();

  return (
    <nav
      className={`fixed inset-x-0 top-0 lg:top-10 z-20 transition ease-in-out duration-300 ${
        isHeaderActive ? 'bg-white shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="flex items-center justify-between w-full px-4 py-4 mx-auto sm:py-6 sm:px-6 lg:px-8 max-w-7xl">
        <Link to="/">
          <span className="sr-only">{config.title}</span>
          <Logo
            className={`${
              isHeaderActive ? '' : 'text-white drop-shadow'
            } w-32 md:w-40`}
          />
        </Link>
        <div className="flex items-center space-x-8">
          <ul className="items-center hidden space-x-8 text-white lg:flex">
            {config.siteNavigation.map((navItem) => (
              <li key={navItem.slug} className="flex">
                <Link
                  to={navItem.slug}
                  className={`font-bold uppercase hover:text-teal transition ease-in-out duration-300 ${
                    isHeaderActive ? 'text-black' : 'text-white text-shadow'
                  }`}
                >
                  {navItem.label}
                </Link>
              </li>
            ))}
          </ul>
          <ul className="items-center hidden space-x-1 text-lg md:flex">
            {socialLinks.map((socialLink) => (
              <li key={socialLink.name} className="flex">
                <a
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    isHeaderActive ? 'text-black' : 'text-white drop-shadow'
                  } transition ease-in-out duration-300 p-1 inline-block hover:text-teal`}
                >
                  <span className="sr-only">{socialLink.name}</span>
                  <socialLink.icon />
                </a>
              </li>
            ))}
          </ul>
          <button
            type="button"
            className="p-2 lg:hidden focus:outline-none focus:ring"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Toggle mobile menu</span>
            <HiMenu
              aria-hidden
              focusable={false}
              className={`w-6 h-6 transition ease-in-out duration-300 ${
                isHeaderActive ? 'text-black' : 'text-white drop-shadow'
              }`}
            />
          </button>
          <MobileMenu
            isMobileMenuOpen={isMobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        </div>
      </div>
    </nav>
  );
}

interface NavItem {
  navItem: {
    slug: string;
    label: string;
  };
}

const NavItem = ({ navItem }) => {
  return (
    <li>
      <Link to={navItem.slug} className="p-2 focus:shadow-outline">
        {navItem.label}
      </Link>
    </li>
  );
};

const MobileMenu = ({ isMobileMenuOpen, setMobileMenuOpen }) => {
  const handleClose = () => setMobileMenuOpen(false);

  const MotionDialogOverlay = motion.custom(DialogOverlay);
  const MotionDialogContent = motion.custom(DialogContent);

  return (
    <AnimatePresence>
      <MotionDialogOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={isMobileMenuOpen}
        isOpen={isMobileMenuOpen}
        onDismiss={handleClose}
        className="fixed inset-0 z-40 flex bg-gray-900 bg-opacity-75"
      >
        <MotionDialogContent
          initial={{ x: '100%' }}
          animate={{ x: '0%' }}
          exit={{ x: '100%' }}
          transition={{ min: 0, max: 100, bounceDamping: 9 }}
          aria-label="Sidebar menu"
          className="relative flex flex-col flex-1 px-4 py-10 overflow-y-auto pointer-events-none sm:px-6 lg:px-8 focus:outline-none focus:ring"
        >
          <div className="pointer-events-auto">
            <button
              type="button"
              onClick={handleClose}
              aria-label="Close sidebar"
              className="flex items-center justify-center w-10 h-10 ml-auto focus:outline-none focus:shadow-outline"
            >
              <HiX
                aria-hidden
                focusable={false}
                className="w-6 h-6 text-white"
              />
            </button>

            <ul className="mt-8 space-y-4 tracking-widest text-right text-white uppercase">
              {config.siteNavigation.map((navItem) => (
                <NavItem key={navItem.slug} navItem={navItem} />
              ))}
            </ul>
          </div>
        </MotionDialogContent>
      </MotionDialogOverlay>
    </AnimatePresence>
  );
};

export { Header };
