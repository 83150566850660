import * as React from 'react';

function EmailIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 215 150" {...props}>
      <path d="M190.87,4H24.13a19.53,19.53,0,0,0-19.5,19.5v103A19.53,19.53,0,0,0,24.13,146H190.87a19.53,19.53,0,0,0,19.5-19.5v-103A19.53,19.53,0,0,0,190.87,4ZM113.46,77.85a9.3,9.3,0,0,1-11.92,0L31.19,19.27H183.81ZM86,84.75l5.81,4.84a24.58,24.58,0,0,0,31.48,0l5.81-4.84,65.59,43.65a4.22,4.22,0,0,1-3.77,2.33H24.13a4.22,4.22,0,0,1-3.77-2.33Zm-66,25.59V29.77L73.69,74.55Zm121.4-35.79,53.78-44.78v80.57Z"></path>
    </svg>
  );
}

export { EmailIcon };
