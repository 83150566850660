import * as React from 'react';
import GatsbyImage, { FluidObject } from 'gatsby-image';

interface Props {
  image: FluidObject;
  onGrey?: boolean;
  imgStyle?: Record<string, unknown>;
}

function Banner({ image, onGrey, imgStyle }: Props) {
  return (
    <div
      className={`
    ${onGrey ? 'bg-grey' : 'bg-white'}
    relative w-full mx-auto max-w-screen-2xl`}
    >
      <div className="relative h-48 overflow-hidden sm:h-0 sm:aspect-w-16 sm:aspect-h-6">
        <div className="absolute inset-0 flex py-px">
          <GatsbyImage fluid={image} imgStyle={imgStyle} className="flex-1" />
        </div>
      </div>
      <svg
        className={`
        ${onGrey ? 'text-grey' : 'text-white'}
        absolute inset-x-0 top-0 block w-full h-14 md:h-28`}
        fill="currentColor"
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="0,0 1,0 0,1" />
      </svg>
      <svg
        className={`
        ${onGrey ? 'text-grey' : 'text-white'}
        absolute inset-x-0 bottom-0 w-full text-white h-14 md:h-28`}
        fill="currentColor"
        viewBox="0 0 1 1"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="0,1 1,0 1,1" />
      </svg>
    </div>
  );
}

export { Banner };
