import * as React from 'react';

function LocationIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 122 159" {...props}>
      <path d="M101.41,131a39.74,39.74,0,0,0-10.3-3.89c-2.07-.51-4.31-1-6.7-1.37,2.57-2.59,5-5.21,7.37-7.81,18.43-20.59,27.78-39.71,27.78-56.83a58.56,58.56,0,0,0-117.12,0c0,17.12,9.35,36.24,27.78,56.84,2.32,2.59,4.79,5.21,7.37,7.8-2.39.4-4.63.86-6.7,1.37A39.74,39.74,0,0,0,20.59,131c-5.12,3-6.19,6.68-6.19,9.21s1.07,6.19,6.19,9.21a39.74,39.74,0,0,0,10.3,3.89c8.13,2,18.82,3.14,30.11,3.14s22-1.11,30.11-3.14a39.74,39.74,0,0,0,10.3-3.89c5.12-3,6.19-6.68,6.19-9.21S106.53,134,101.41,131ZM14.69,61.1a46.31,46.31,0,0,1,92.62,0c0,28.32-34.1,59.3-46.31,69.48C48.78,120.4,14.69,89.42,14.69,61.1Zm34.56,75.57c4.84,4.24,8,6.63,8,6.65l1.13.86a121,121,0,0,1-22.32-2.24,47.1,47.1,0,0,1-6.31-1.73,49.87,49.87,0,0,1,6.31-1.73A102.54,102.54,0,0,1,49.25,136.67Zm15.45,6.66s3.2-2.42,8-6.66a104.35,104.35,0,0,1,13.14,1.81,48.68,48.68,0,0,1,6.31,1.73,47.54,47.54,0,0,1-6.31,1.73,121,121,0,0,1-22.32,2.24Z"></path>
      <path d="M97.48,61.22A36.48,36.48,0,1,0,61,97.7,36.52,36.52,0,0,0,97.48,61.22Zm-12.25,0A24.23,24.23,0,1,1,61,37,24.26,24.26,0,0,1,85.23,61.22Z"></path>
    </svg>
  );
}

export { LocationIcon };
