import * as React from 'react';

import { Header } from './header';
import { Footer } from './footer';
import { Topbar } from './topbar';
import { HeaderIndicator } from './header-indicator';

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans antialiased fill-available">
      <Topbar />
      <Header />
      <main className="flex-1">
        <HeaderIndicator />
        {children}
      </main>
      <Footer />
    </div>
  );
}

export { Layout };
