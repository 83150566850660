import * as React from 'react';

import { EmailIcon, LocationIcon, PhoneIcon } from '../components/vectors';
import config from '../utils/config';

function InfoBar() {
  return (
    <ul className="flex items-center justify-around w-full lg:justify-center sm:space-x-8">
      <li>
        <a
          href={`mailto:${config.email}`}
          className="flex items-center space-x-3 transition duration-150 ease-out rounded hover:text-teal"
        >
          <EmailIcon className="w-6 h-6" />
          <span>{config.email}</span>
        </a>
      </li>
      <li>
        <a
          href={`tel:${config.phone}`}
          className="flex items-center space-x-3 transition duration-150 ease-out rounded hover:text-teal"
        >
          <PhoneIcon className="w-6 h-6" />
          <span>{config.phone}</span>
        </a>
      </li>
      <li>
        <span className="flex items-center space-x-3 transition duration-150 ease-out rounded hover:text-teal">
          <LocationIcon className="w-6 h-6" />
          <span>
            {config.address.line1}, {config.address.line2}
          </span>
        </span>
      </li>
    </ul>
  );
}

export { InfoBar };
