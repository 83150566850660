import * as React from 'react';
import { Link } from 'gatsby';

import config from '../utils/config';
import { Logo } from './vectors';

function Footer() {
  return (
    <footer className="w-full mx-auto overflow-hidden bg-black">
      <div className="w-full grid-cols-6 gap-12 px-4 py-12 mx-auto bg-black lg:grid max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-center col-span-2">
          <Link to="/" className="w-full max-w-xs">
            <span className="sr-only">{config.title}</span>
            <Logo className="text-yellow" />
          </Link>
        </div>
        <div className="grid-cols-3 col-span-4 mt-8 border-b border-white sm:grid lg:mt-0">
          <nav className="flex flex-row flex-wrap sm:flex-col sm:col-span-1">
            {config.siteNavigation.map((node) => (
              <div key={node.slug} className="w-1/2 py-1 sm:w-full">
                <Link
                  to={node.slug}
                  className="text-base leading-6 text-white transition duration-150 ease-in-out hover:text-teal"
                >
                  {node.label}
                </Link>
              </div>
            ))}
          </nav>
          <div className="flex flex-col justify-between col-span-2 mt-8 sm:mt-0">
            <ul className="text-white">
              <li className="py-1">
                <a
                  href={`tel:${config.phone}`}
                  className="grid grid-cols-7 text-base leading-6 text-white transition duration-150 ease-in-out hover:text-teal"
                >
                  <span className="col-span-2">Phone: </span>
                  <span className="col-span-5">{config.phone}</span>
                </a>
              </li>
              <li className="py-1">
                <a
                  href={`mailto:${config.email}`}
                  className="grid grid-cols-7 text-base leading-6 text-white transition duration-150 ease-in-out hover:text-teal"
                >
                  <span className="col-span-2">Email: </span>
                  <span className="col-span-5 break-all">{config.email}</span>
                </a>
              </li>
              <li className="grid grid-cols-7 py-1">
                <span className="col-span-2">Office Hours: </span>
                <span className="col-span-5">{config.officeHours}</span>
              </li>
              <li className="grid grid-cols-7 py-1">
                <span className="col-span-2">Address: </span>
                <span className="col-span-5">
                  {config.address.line1}, {config.address.line2}
                </span>
              </li>
            </ul>
            <p className="py-1 mt-16 text-xs tracking-wider text-center text-white uppercase sm:text-left">
              <a
                href="https://pd.design"
                rel="noopener noreferrer"
                target="_blank"
              >
                Designed and developed by PD
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
