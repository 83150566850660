import * as React from 'react';
import { useSiteContext } from '../hooks';

import { InfoBar } from './info-bar';

function Topbar() {
  const { isHeaderActive } = useSiteContext();

  return (
    <div
      className={`fixed inset-x-0 top-0 z-30 hidden h-12 text-sm font-bold tracking-wide text-center text-black ${
        isHeaderActive ? 'bg-grey' : 'bg-white'
      } lg:flex sm:items-center sm:py-0 sm:px-6 lg:px-8`}
    >
      <div className="flex flex-wrap items-center justify-between w-full">
        <InfoBar />
      </div>
    </div>
  );
}

export { Topbar };
