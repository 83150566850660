import PropTypes from 'prop-types';
import * as React from 'react';

import { Error } from './error';

function TextArea({
  label,
  name,
  required = true,
  rows = 4,
  register,
  errors,
  labelHidden,
  bgTransparent,
}) {
  return (
    <div className="sm:col-span-2">
      <label htmlFor={name}>
        <span
          className={`${
            labelHidden ? 'sr-only' : 'block'
          } text-sm font-medium leading-5 text-gray-700`}
        >
          {label}
          {required && ' *'}
        </span>
        <div className="relative mt-1 shadow-sm">
          <textarea
            id={name}
            name={name}
            rows={rows}
            required={required}
            aria-invalid={!!errors[name]}
            placeholder={label}
            ref={register({
              required: <Error message={`${label} is a required field`} />,
            })}
            className={`
            ${bgTransparent ? 'border border-gray-300' : 'border-none'}
            block w-full px-4 py-3 placeholder-black transition duration-150 ease-in-out border-black rounded-none form-input bg-white}`}
          />
        </div>
      </label>
      {errors[name]?.message}
    </div>
  );
}

TextArea.propTypes = {
  errors: PropTypes.object,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool,
  rows: PropTypes.number,
  labelHidden: PropTypes.bool,
  bgTransparent: PropTypes.bool,
};

export { TextArea };
