import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { NetlifyForm, Input, TextArea } from './form-elements';
import config from '../utils/config';

function Contact({ bgClassName }) {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <article
      id="contact-form"
      className={`relative ${bgClassName ? bgClassName : 'bg-white'}`}
    >
      <div className="w-full grid-cols-2 gap-8 px-4 py-24 mx-auto md:grid max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-col justify-center mx-auto md:max-w-lg">
          <h2 className="heading-2">Get in touch</h2>
          <ul className="mt-8 text-black">
            <li className="py-1">
              <a
                href={`tel:${config.phone}`}
                className="grid grid-cols-7 text-base leading-6"
              >
                <p className="col-span-3">Phone:</p>
                <span className="col-span-4 font-semibold xl:text-base">
                  {config.phone}
                </span>
              </a>
            </li>
            <li className="py-1">
              <a
                href={`mailto:${config.email}`}
                className="grid grid-cols-7 text-base leading-6"
              >
                <p className="col-span-3">Email:</p>
                <span className="col-span-4 font-semibold break-all xl:text-base">
                  {config.email}
                </span>
              </a>
            </li>
            <li className="grid grid-cols-7 py-1">
              <p className="col-span-3">Office Hours: </p>
              <span className="col-span-4 font-semibold xl:text-base">
                Monday to Friday 9am-5pm
              </span>
            </li>
            <li className="grid grid-cols-7 py-1">
              <p className="col-span-3">Address: </p>
              <span className="col-span-4 font-semibold xl:text-base">
                {config.address.line1}, {config.address.line2}
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-8 md:mt-0">
          <NetlifyForm
            handleSubmit={handleSubmit}
            setIsSubmitting={setIsSubmitting}
            className="grid grid-cols-1 mx-auto md:max-w-xl gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <Input
              name="full_name"
              label="Full Name"
              labelHidden
              isFullWidth
              bgTransparent
              register={register}
              errors={errors}
            />
            <Input
              name="email_address"
              label="Email Address"
              labelHidden
              type="email"
              isFullWidth
              bgTransparent
              register={register}
              errors={errors}
            />
            <Input
              name="contact_number"
              label="Contact number"
              labelHidden
              type="tel"
              isFullWidth
              bgTransparent
              register={register}
              errors={errors}
            />
            <TextArea
              name="message"
              label="Message"
              labelHidden
              bgTransparent
              register={register}
              errors={errors}
            />
            <div className="sm:col-span-2">
              <span className="inline-flex w-full shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`${
                    isSubmitting ? 'opacity-50 cursor-wait' : ''
                  } w-full text-white button bg-teal`}
                >
                  Submit
                </button>
              </span>
            </div>
          </NetlifyForm>
        </div>
      </div>
    </article>
  );
}

Contact.propTypes = {
  bgClassName: PropTypes.string,
};

export { Contact };
