import * as React from 'react';
import GatsbyImage, { FluidObject } from 'gatsby-image';

interface Props {
  aspectClassNames?: string;
  children?: React.ReactNode;
  image: FluidObject;
  imgStyle?: Record<string, unknown>;
}

function Hero({ aspectClassNames, children, image, imgStyle }: Props) {
  return (
    <article
      className={`
      ${
        aspectClassNames
          ? aspectClassNames
          : 'aspect-w-4 aspect-h-3 sm:aspect-w-21 sm:aspect-h-9'
      }
      relative bg-gray-600 z-10`}
    >
      <div className="absolute inset-0 flex">
        <GatsbyImage fluid={image} imgStyle={imgStyle} className="flex-1" />
      </div>
      {children && <div>{children}</div>}
    </article>
  );
}

export { Hero };
