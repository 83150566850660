import * as React from 'react';

function PhoneIcon(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 183 150" {...props}>
      <path d="M180,45.53c-.49-12.35-10.6-23.48-28.45-31.34-16.22-7.13-37.52-11-60-11-22.74,0-44.23,4-60.51,11.29-18,8.05-27.94,19.43-27.94,32V70.4a6.74,6.74,0,0,0,6.73,6.72H25.49L17.64,89.41a11.18,11.18,0,0,0-1.77,6v43.9a7.5,7.5,0,0,0,7.5,7.5H159.63a7.5,7.5,0,0,0,7.5-7.5V95.46a11.18,11.18,0,0,0-1.77-6l-7.85-12.29h15.71A6.74,6.74,0,0,0,180,70.4Zm-13.59,9.41v8.59H132.42V54.94Zm-115.78,0v8.59H16.64V54.94Zm76.58-17.72a159.49,159.49,0,0,0-35.91-3.94A159.81,159.81,0,0,0,55.87,37.1l-5.29,1.2v3.05H18c2.65-5.2,9.12-10.29,18.57-14.52,14.57-6.51,34.09-10.1,55-10.1,20.63,0,40,3.52,54.52,9.91,9.55,4.2,16.3,9.46,19,14.71H132.42V38.44ZM29.46,98.44a7.86,7.86,0,0,1,1.23-4.21L41.63,77.12H57.45a6.73,6.73,0,0,0,6.72-6.72V49.26a155.61,155.61,0,0,1,54.66.09V70.4a6.74,6.74,0,0,0,6.73,6.72h15.81l10.94,17.11a7.86,7.86,0,0,1,1.23,4.21V129.2a4.08,4.08,0,0,1-4.08,4.07H33.54a4.08,4.08,0,0,1-4.08-4.07Z"></path>
      <path d="M85.68,126.32a31.17,31.17,0,0,0,5.89.57,29.77,29.77,0,1,0-5.89-.57Zm-9.94-33a16.15,16.15,0,0,1,12.05-12,16.36,16.36,0,0,1,3.66-.41A16.15,16.15,0,1,1,75.74,93.36Z"></path>
    </svg>
  );
}

export { PhoneIcon };
