import { SiFacebook, SiInstagram, SiLinkedin } from 'react-icons/si';

const socialLinks = [
  {
    name: 'Facebook',
    icon: SiFacebook,
    link: 'https://www.facebook.com/RMAviationAus',
  },
  {
    name: 'Instagram',
    icon: SiInstagram,
    link: 'https://www.instagram.com/rmaviationaus/',
  },
  {
    name: 'LinkedIn',
    icon: SiLinkedin,
    link: 'https://www.linkedin.com/company/r-m-aircraft/',
  },
];

export { socialLinks };
