import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import GatsbyImage from 'gatsby-image';

import { useGraphQL } from '../hooks';

import { NetlifyForm, Input, TextArea, Select } from './form-elements';

function ContactFormHero() {
  const { contactFormBGImage } = useGraphQL();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <article className="relative overflow-hidden bg-gray-600">
      <div className="absolute inset-0 aspect-w-4 aspect-h-3 sm:relative sm:h-0 sm:aspect-ratio-16/9">
        <div className="absolute inset-0 flex">
          <GatsbyImage
            fluid={contactFormBGImage.childImageSharp.fluid}
            className="flex-1"
          />
        </div>
      </div>

      <div
        id="contact-form"
        className="relative inset-0 flex items-center px-8 py-12 lg:py-24 sm:absolute"
      >
        <div className="flex flex-col justify-center w-full max-w-2xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-white uppercase">
            Get in touch
          </h2>

          <NetlifyForm
            handleSubmit={handleSubmit}
            setIsSubmitting={setIsSubmitting}
            className="grid grid-cols-1 mt-8 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            <Input
              name="full_name"
              label="Full Name"
              labelHidden
              isFullWidth
              register={register}
              errors={errors}
            />
            <Input
              name="company_name"
              label="Company Name"
              labelHidden
              required={false}
              isFullWidth
              register={register}
              errors={errors}
            />
            <Input
              name="contact_number"
              label="Contact number"
              labelHidden
              type="tel"
              isFullWidth
              register={register}
              errors={errors}
            />
            <Input
              name="email_address"
              label="Email Address"
              labelHidden
              type="email"
              isFullWidth
              register={register}
              errors={errors}
            />
            <Select
              name="service"
              label="Service"
              defaultValue="Please select the service you would like to enquire about"
              isFullWidth
              options={[
                'International Services',
                'Australian Services',
                'Aircraft Hire',
                'Partnership Opportunities',
              ]}
              register={register}
              errors={errors}
            />
            <TextArea
              name="message"
              label="Message"
              labelHidden
              register={register}
              errors={errors}
            />
            <div className="sm:col-span-2">
              <span className="inline-flex w-full shadow-sm">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`inline-flex items-center tracking-wide justify-center w-full px-6 py-3 text-base font-medium leading-6 text-white uppercase transition duration-150 ease-in-out bg-teal border-none rounded-none ${
                    isSubmitting ? 'opacity-50 cursor-wait' : ''
                  }`}
                >
                  Submit
                </button>
              </span>
            </div>
          </NetlifyForm>
        </div>
      </div>
    </article>
  );
}

export { ContactFormHero };
