import * as React from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { useGraphQL } from '../hooks';

function OurTeam() {
  const {
    justinMace,
    niccoleMace,
    robJohnston,
    kaylahHacio,
    cassandaShafer,
  } = useGraphQL();
  const teamMembers = [
    {
      image: justinMace.childImageSharp.fluid,
      name: 'Justin Mace',
      position: 'CEO & chief pilot',
    },
    {
      image: niccoleMace.childImageSharp.fluid,
      name: 'Niccole Mace',
      position: 'chief operating officer',
    },
    {
      image: kaylahHacio.childImageSharp.fluid,
      name: 'Kaylah Hacio',
      position: 'relationship manager',
    },
    {
      image: robJohnston.childImageSharp.fluid,
      name: 'Rob Johnston',
      position: 'head of strategy',
    },
    {
      image: cassandaShafer.childImageSharp.fluid,
      name: 'Cassanda Shafer',
      position: 'accounts officer',
    },
  ];

  return (
    <article className="relative w-full px-8 py-24 mx-auto overflow-hidden max-w-screen-2xl">
      <h2 className="text-center heading-2">Our team </h2>
      <div className="flex flex-wrap justify-center max-w-3xl mx-auto mt-8">
        {teamMembers.map((member, i) => {
          return (
            <div
              key={member.name}
              className={`w-full px-4 mt-8 ${i < 2 ? 'sm:w-1/2' : 'sm:w-1/3'}`}
            >
              <div className="relative w-full aspect-w-11 aspect-h-16">
                <div className="absolute inset-0 flex">
                  <GatsbyImage fluid={member.image} className="flex-1" />
                </div>
              </div>
              <p className="mt-2 text-xl font-bold text-center uppercase">
                {member.name}
              </p>
              <p className="mt-2 font-medium text-center uppercase text-md">
                {member.position}
              </p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center mt-8">
        <Link
          to="/our-team/"
          aria-label="Learn more about our team"
          className="button"
        >
          Learn more
        </Link>
      </div>
    </article>
  );
}

export { OurTeam };
