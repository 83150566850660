const defaultTheme = require('tailwindcss/defaultTheme');
const aspectRatio = require('@tailwindcss/aspect-ratio');
const typography = require('@tailwindcss/typography');

module.exports = {
  purge: ['./src/**/*.{js,ts,jsx,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      aspectRatio: {
        21: '21',
      },
      colors: {
        black: '#0d0d0d',
        teal: '#2cb0b0',
        yellow: '#fcda34',
        grey: '#f3f1f1',
      },
      fontFamily: {
        sans: ['Avenir', 'Montserrat', ...defaultTheme.fontFamily.sans],
      },
      inset: {
        '1/5': '20%',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            '*': {
              fontWeight: theme('fontWeight.medium'),
            },
            'h1, h2, h3, h4, h5, h6': {
              color: theme('colors.teal'),
              fontWeight: theme('fontWeight.bold'),
            },
            p: {
              fontSize: theme('text.lg'),
            },
            'ul > li:before': {
              backgroundColor: theme('colors.teal'),
            },
          },
        },
      }),
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    // https://github.com/tailwindlabs/tailwindcss-aspect-ratio#readme
    aspectRatio,
    // See https://github.com/tailwindlabs/tailwindcss-typography for details
    typography,
  ],
};
